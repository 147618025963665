import React from 'react';
import Helmet from 'react-helmet';
import { Tabs } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import './BalancePage.scss';

import { ApplicationLayout } from '../application/layouts/application-layout/ApplicationLayout';
import { PrivateRoutes } from '../../constants/routes';
import { WithdrawalsContainer } from './withdrawals/WithdrawalsContainer';
import { BalanceContainer } from './balance/BalanceContainer';

enum MenuKey {
  Balance = 'balance',
  BalanceWithdrawals = 'balanceWithdrawals',
}

const MenuRoutes: {
  [key: string]: string
} = {
  [MenuKey.Balance]: PrivateRoutes.BalanceInfo,
  [MenuKey.BalanceWithdrawals]: PrivateRoutes.BalanceWithdrawals,
};

export const BalancePage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      key: MenuKey.Balance,
      label: 'Баланс',
      children: <BalanceContainer />,
    },
    {
      key: MenuKey.BalanceWithdrawals,
      label: 'Вывод средств',
      children: <WithdrawalsContainer />,
    },
  ];

  let selectedKey: string = MenuKey.Balance;
  if (location.pathname !== selectedKey) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}$`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <ApplicationLayout>
      <Helmet>
        <title>Партнеры</title>
      </Helmet>
      <div className="BalancePage">
        <Tabs
          activeKey={selectedKey}
          onChange={(tab) => {
            navigate(MenuRoutes[tab]);
          }}
          destroyInactiveTabPane
          className="clickpass-tabs"
          items={items}
        />
      </div>
    </ApplicationLayout>
  );
};

export default BalancePage;
