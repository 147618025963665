import React, { useState } from 'react';
import { useMount } from 'react-use';

import { fetchOrganizerWithdrawals, Withdrawal } from '../../../api/bookkeeping';

import { Withdrawals } from './Withdrawals';

export const WithdrawalsContainer = () => {
  const [ loading, setLoading ] = useState(true);
  const [ withdrawals, setWithdrawals ] = useState<Withdrawal[]>();
  useMount(async () => {
    try {
      const response = await fetchOrganizerWithdrawals();
      setWithdrawals(response.withdrawals);
    } finally {
      setLoading(false);
    }
  });
  return (
    <Withdrawals
      loading={loading}
      withdrawals={withdrawals}
    />
  );
};
