import React from 'react';
import { produce } from 'immer';
import dayjs from 'dayjs';
import type { SortOrder } from 'antd/lib/table/interface';
import { ProductSummaryFilter } from '../../../../api/orders';

export const initPagination = {
  pageSize: 10,
  current: 1,
  total: 0,
};

export interface Sorting {
  columnKey?: React.Key;
  order?: SortOrder;
}

export enum ListType {
  ByBuyDate = 'byBuyDate',
  ByEventDate = 'byEventDate',
}

export interface ProductSummaryListContextState {
  listItems: {
    multiTicketComponents: any[];
    productItems: any[];
  };
  listType: ListType;
  loading: boolean;
  accountId: string;
  filter: ProductSummaryFilter;
  pagination: {
    pageSize: number,
    current: number,
    total: number,
  };
  sorting: Sorting;
}

export interface InitState {
  filter?: ProductSummaryFilter;
  accountId: string;
  listType: ListType;
}

export const initialState = (initValues: InitState): ProductSummaryListContextState => ({
  listItems: {
    multiTicketComponents: [],
    productItems: [],
  },
  listType: initValues.listType,
  loading: false,
  accountId: initValues?.accountId ?? 'undefined',
  filter: initValues?.filter ?? {
    dates: [dayjs().subtract(1, 'month').startOf('day'), dayjs().startOf('day')],
    eventDates: [dayjs().subtract(1, 'month').startOf('day'), dayjs().startOf('day')],
  },
  pagination: initPagination,
  sorting: {
    columnKey: initValues.listType === ListType.ByBuyDate ? 'created' : 'eventDate',
    order: 'descend',
  },
});

export const STORE_LIST_ITEMS = 'STORE_LIST_ITEMS';
export const storeListItems = (multiTicketComponents: any[], productItems: any[]) => ({
  type: STORE_LIST_ITEMS, payload: { multiTicketComponents, productItems },
});

export const STORE_PAGINATION = 'STORE_PAGINATION';
export const storePagination = (pagination: ProductSummaryListContextState['pagination']) => ({
  type: STORE_PAGINATION, payload: { pagination },
});

export const STORE_SORTING = 'STORE_SORTING';
export const storeSorting = (sorting: Sorting) => ({
  type: STORE_SORTING, payload: { sorting },
});

export const STORE_FILTER = 'STORE_FILTER';
export const storeFilter = (filter: ProductSummaryFilter) => ({
  type: STORE_FILTER, payload: { filter },
});

export const SET_PRODUCT_SUMMARY_LIST_LOADING = 'SET_PRODUCT_SUMMARY_LIST_LOADING';
export const setProductSummaryListLoading = (loading: boolean) => ({
  type: SET_PRODUCT_SUMMARY_LIST_LOADING, payload: { loading },
});

const productSummaryListReducer = (
  state: ProductSummaryListContextState,
  action: any,
) => produce(state, (draftState) => {
  switch (action.type) {
    case STORE_LIST_ITEMS: {
      const { multiTicketComponents, productItems } = (action as ReturnType<typeof storeListItems>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.listItems = { multiTicketComponents, productItems };
      break;
    }
    case STORE_SORTING: {
      const { sorting } = (action as ReturnType<typeof storeSorting>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.sorting = sorting;
      break;
    }
    case SET_PRODUCT_SUMMARY_LIST_LOADING: {
      const { loading } = (action as ReturnType<typeof setProductSummaryListLoading>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.loading = loading;
      break;
    }
    case STORE_PAGINATION: {
      const { pagination } = (action as ReturnType<typeof storePagination>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.pagination = pagination;
      break;
    }
    case STORE_FILTER: {
      const { filter } = (action as ReturnType<typeof storeFilter>).payload;
      // eslint-disable-next-line no-param-reassign
      draftState.filter = filter;
      break;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
});

export default productSummaryListReducer;
