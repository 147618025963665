import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import SyntaxHighlighter from 'react-syntax-highlighter';
import dayjs from 'dayjs';

interface Partner {
  tinkoffShop: {
    created: string;
  };
  name: string;
  tinkoffShopCode: string;
}

const columns: ColumnsType<Partner> = [
  {
    title: 'Создан',
    dataIndex: [ 'tinkoffShop', 'created' ],
    width: '160px',
    sorter: (a, b) => (
      dayjs(a?.tinkoffShop?.created).valueOf() - dayjs(b?.tinkoffShop?.created).valueOf()
    ),
    render: (value: string) => {
      if (value) {
        return dayjs(value).format('DD/MM/YYYY HH:mm');
      }
      return '';
    },
  }, {
    title: 'Название',
    dataIndex: 'name',
    sorter: (a, b) => (a.name > b.name ? 1 : -1),
  }, {
    title: 'Точка Орг.',
    dataIndex: 'tinkoffShopCode',
    width: '160px',
    sorter: (a, b) => (parseInt(a.tinkoffShopCode, 10) > parseInt(b.tinkoffShopCode, 10) ? 1 : -1),
  },
];

interface Props {
  partners: Partner[];
}

const PartnersList = ({ partners }: Props) => (
  <Table<Partner>
    columns={columns}
    dataSource={partners}
    pagination={false}
    rowKey="id"
    showSorterTooltip={false}
    expandable={{
      expandedRowRender: (record) => (
        <SyntaxHighlighter language="json">
          {JSON.stringify(record.tinkoffShop, null, 2)}
        </SyntaxHighlighter>
      ),
    }}
    style={{ width: '100%' }}
    // TODO: Косячит переключение в полноэкранный режим
    scroll={{ x: '100%', y: 'calc(100vh - 142px)' }}
  />
);

export default PartnersList;
