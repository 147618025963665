import React, { Dispatch } from 'react';
import { thunk } from 'redux-thunk';
import { createReducer } from 'react-use';

import productSummaryListReducer, {
  ProductSummaryListContextState,
  initialState,
  InitState,
} from './productSummaryListReducer';

const useThunkReducer = createReducer<any, ProductSummaryListContextState>(thunk as any);

const ProductSummaryListStateContext = React.createContext<ProductSummaryListContextState | null>(null);
export const ProductSummaryListDispatchContext = React.createContext<Dispatch<any> | null>(null);

interface Props {
  children: React.ReactNode,
  initState: InitState
}

const ProductSummaryListProvider: React.FC<Props> = ({ children, initState }) => {
  const [state, dispatch] = useThunkReducer(productSummaryListReducer, initialState(initState));

  return (
    <ProductSummaryListStateContext.Provider value={state}>
      <ProductSummaryListDispatchContext.Provider value={dispatch}>
        {children}
      </ProductSummaryListDispatchContext.Provider>
    </ProductSummaryListStateContext.Provider>
  );
};

function useProductSummaryListState() {
  const context = React.useContext(ProductSummaryListStateContext);
  if (context === null) {
    throw new Error('useProductSummaryListState must be used within a ProductSummaryListStateContext');
  }
  return context;
}

function useProductSummaryListDispatch() {
  const context = React.useContext(ProductSummaryListDispatchContext);
  if (context === null) {
    throw new Error('useProductSummaryListDispatch must be used within a ProductSummaryListDispatchContext');
  }
  return context;
}

export {
  ProductSummaryListProvider,
  useProductSummaryListState,
  useProductSummaryListDispatch,
};
