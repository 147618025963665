import React from 'react';
import { Spin, Button, Typography, Form } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { InputNumberWrapper } from 'utils/form-helpers/antd-formik';

import './Balance.scss';

interface Props {
  balance?: number;
  loading: boolean;
  onSubmit: (values: any, formikBag: FormikHelpers<any>) => void;
}

export const Balance = ({ balance, loading, onSubmit }: Props) => (
  <Spin spinning={loading} wrapperClassName="Balance">
    <div className="Balance-balance">
      <Typography.Title level={3}>Текущий баланс</Typography.Title>
      <div>{`${balance ?? ''} рублей`}</div>
    </div>
    <Typography.Title level={3}>Запросить перевод</Typography.Title>
    <Formik
      initialValues={{
        amount: 0,
      }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form className="Balance-form">
          <InputNumberWrapper
            name="amount"
            antFieldProps={{ label: 'Сумма перевода' }}
            className="Balance-numberInput"
            addonAfter="₽"
            precision={2}
          />
          <Button onClick={() => handleSubmit()} loading={isSubmitting}>Перевести</Button>
        </Form>
      )}
    </Formik>
  </Spin>
);
