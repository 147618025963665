import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography } from 'antd';
import cn from 'classnames';

import { PrivateRoutes } from '../../../constants/routes';

import './Menu.scss';
import { Account, OrderWorkflowType } from '../../../api';

interface MenuDependencies {
  isAdministrator: boolean;
  isOrganizer: boolean;
  isDistributor: boolean;
  isInspector: boolean;
  account?: Account;
}

const Menu = ({ account, isAdministrator, isOrganizer, isDistributor, isInspector }: MenuDependencies) => {
  const location = useLocation();
  const organizerMenu = (
    isOrganizer
      ? (
        <>
          <div className="Menu-title">
            <Typography.Title level={4}>Организатор</Typography.Title>
          </div>
          <Link to="/products">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/products' })}>
              Товары
            </div>
          </Link>
          <Link to="/purchases">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/purchases' })}>
              Продажи
            </div>
          </Link>
          { /*
          {
            account?.preferableWorkflowType === OrderWorkflowType.NOMINAL_SPLIT
            && (
              <Link to={PrivateRoutes.BalanceInfo}>
                <div
                  className={cn({
                    'Menu-item': true,
                    'Menu-item-active': new RegExp(`^${PrivateRoutes.Balance}`).test(location.pathname),
                  })}
                >
                  Баланс
                </div>
              </Link>
            )
          }
          */}
        </>
      ) : null
  );

  const distributorMenu = (
    (isDistributor || isOrganizer)
      ? (
        <>
          <div className="Menu-title">
            <Typography.Title level={4}>Распространитель</Typography.Title>
          </div>
          <Link to="/orders">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/orders' })}>
              Продажи
            </div>
          </Link>
          <Link to="/child-partners">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/child-partners' })}>
              Связанные партнеры
            </div>
          </Link>
        </>
      ) : null
  );

  const settingsMenu = (
    (isAdministrator || isOrganizer || isDistributor)
      ? (
        <>
          <div className="Menu-title">
            <Typography.Title level={4}>Настройки</Typography.Title>
          </div>
          <div>
            {
              (isOrganizer || isDistributor) && (
                <Link to={PrivateRoutes.Inspectors}>
                  <div
                    className={cn({
                      'Menu-item': true,
                      'Menu-item-active': location.pathname === PrivateRoutes.Inspectors,
                    })}
                  >
                    Пользователи
                  </div>
                </Link>
              )
            }
            {
              isAdministrator && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  <Link to={PrivateRoutes.LoginAs}>
                    <div
                      className={cn({
                        'Menu-item': true,
                        // eslint-disable-next-line max-len
                        'Menu-item-active': location.pathname === PrivateRoutes.LoginAs,
                      })}
                    >
                      Зайти под пользователем
                    </div>
                  </Link>
                </>
              )
            }
            {
              isAdministrator && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  <Link to={PrivateRoutes.Partners.replace(':tab', 'partners-list')}>
                    <div
                      className={cn({
                        'Menu-item': true,
                        // eslint-disable-next-line max-len
                        'Menu-item-active': new RegExp(PrivateRoutes.Partners.replace(':tab', '[a-z0-9-]+')).test(location.pathname),
                      })}
                    >
                      Партнеры
                    </div>
                  </Link>
                </>
              )
            }
          </div>
        </>
      ) : null
  );

  const documentsMenu = (
    (isDistributor || isOrganizer)
      ? (
        <>
          <div className="Menu-title">
            <Typography.Title level={4}>Документы</Typography.Title>
          </div>
          <Link to="/reports">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/reports' })}>
              Отчеты по расписанию
            </div>
          </Link>
        </>
      ) : null
  );

  const inspectorMenu = (
    (isInspector || isOrganizer)
      ? (
        <>
          <br />
          <Link to="/scanner">
            <div className={cn({ 'Menu-item': true, 'Menu-item-active': location.pathname === '/scanner' })}>
              Сканер
            </div>
          </Link>
          <br />
        </>
      ) : null
  );

  return (
    <div className="Menu">
      {organizerMenu}
      {distributorMenu}
      {settingsMenu}
      {documentsMenu}
      {inspectorMenu}
    </div>
  );
};

export default Menu;
