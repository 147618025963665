import isEqual from 'lodash.isequal';
import React, { useCallback } from 'react';
import { useMount } from 'react-use';
import { ProductsSummary } from './ProductsSummary';
import {
  ProductSummaryListProvider,
  useProductSummaryListDispatch,
  useProductSummaryListState,
} from './context/productSummaryListContext';
import {
  listItemSelector,
  listLoadingSelector,
  sortingSelector,
  paginationSelector,
  filterSelector,
  listTypeSelector,
} from './context/productSummaryListSelectors';
import {
  ProductSummaryListContextState,
  storePagination,
  storeSorting,
  storeFilter,
  initPagination,
  ListType,
} from './context/productSummaryListReducer';
import { getList, getListCsv } from './context/productsSumaryListThunks';
import { useAuthState } from '../../authentication/context/authContext';
import { currentUserSelector } from '../../authentication/context/authSelectors';

export const ProductsSummaryContainer = () => {
  const state = useProductSummaryListState();
  const dispatch = useProductSummaryListDispatch();
  const listItems = listItemSelector(state);
  const filter = filterSelector(state);
  useMount(() => {
    dispatch(getList);
  });

  return (
    <ProductsSummary
      listType={listTypeSelector(state)}
      productItems={listItems}
      sorting={sortingSelector(state)}
      loading={listLoadingSelector(state)}
      pagination={paginationSelector(state)}
      filter={filter}
      onSetPagination={useCallback((pagination: ProductSummaryListContextState['pagination']) => {
        dispatch(storePagination(pagination));
      }, [dispatch])}
      onSetSorting={useCallback((sorting) => {
        dispatch(storeSorting(sorting));
      }, [dispatch])}
      onSetFilter={useCallback((newFilter: ProductSummaryListContextState['filter']) => {
        if (!isEqual(newFilter, filter)) {
          dispatch(storeFilter(newFilter));
          dispatch(storePagination(initPagination));
          dispatch(getList);
        }
      }, [dispatch, filter])}
      onFetch={useCallback(() => dispatch(getList), [dispatch])}
      onExportCsv={useCallback(() => {
        dispatch(getListCsv);
      }, [dispatch])}
    />
  );
};

interface Props {
  listType: ListType;
}

export const PurchasesPageWrapper = ({ listType }: Props) => {
  const state = useAuthState();
  const { accountId } = currentUserSelector(state)!;
  return (
    <ProductSummaryListProvider initState={{ accountId, listType }}>
      <ProductsSummaryContainer />
    </ProductSummaryListProvider>
  );
};
