import React from 'react';
import { Button, DatePicker, Space, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import type { SorterResult } from 'antd/lib/table/interface';
import { DownloadOutlined } from '@ant-design/icons';

import { ListType, Sorting, ProductSummaryListContextState } from './context/productSummaryListReducer';
import { ExtraTabSection } from '../PurchasesPage';
import { RefundButtonContainer } from './refund/RefundButtonContainer';
import type { ProductListItem } from './context/productSummaryListSelectors';

interface Props {
  listType: ListType;
  productItems: ProductListItem[];
  sorting: Sorting;
  onFetch: () => void,
  pagination: ProductSummaryListContextState['pagination'],
  onSetPagination: (pagination: ProductSummaryListContextState['pagination']) => void,
  filter: ProductSummaryListContextState['filter'],
  onSetFilter: (filter: ProductSummaryListContextState['filter']) => void,
  loading: boolean,
  onSetSorting: (sorting: Sorting) => void,
  onExportCsv: () => void;
}

export class ProductsSummary extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  handleTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<any> | SorterResult<any>[]) {
    const { onSetPagination, onFetch, onSetSorting } = this.props;
    onSetPagination(pagination as ProductSummaryListContextState['pagination']);
    if (!Array.isArray(sorter)) {
      onSetSorting({
        columnKey: sorter.columnKey,
        order: sorter.order,
      });
    }
    onFetch();
  }

  render() {
    const { productItems, sorting, pagination, loading, onExportCsv, filter, onSetFilter, listType } = this.props;
    const commonColumns: ColumnsType<ProductListItem> = [{
      title: 'Статус (Билет)',
      dataIndex: 'status',
      width: 150,
      key: 'status',
      sorter: true,
      sortOrder: sorting.columnKey === 'status' ? sorting.order : undefined,
    }, {
      title: 'Погашен (Билет)',
      dataIndex: 'hasConsumed',
      width: 200,
    }, {
      title: 'Почта',
      dataIndex: 'customerEmail',
      width: 200,
      key: 'customerEmail',
      sorter: true,
      sortOrder: sorting.columnKey === 'customerEmail' ? sorting.order : undefined,
    }, {
      title: 'Имя',
      dataIndex: 'customerName',
      width: 200,
      key: 'customerName',
      sorter: true,
      sortOrder: sorting.columnKey === 'customerName' ? sorting.order : undefined,
    }, {
      title: 'Телефон',
      dataIndex: 'customerPhone',
      width: 165,
      key: 'customerPhone',
      sorter: true,
      sortOrder: sorting.columnKey === 'customerPhone' ? sorting.order : undefined,
    }, {
      title: 'Название',
      dataIndex: 'title',
      key: 'specification.title',
      sorter: true,
      sortOrder: sorting.columnKey === 'specification.title' ? sorting.order : undefined,
    }, {
      title: 'Категория',
      dataIndex: 'categoryName',
    }, {
      title: 'Сумма',
      dataIndex: 'price',
      width: 85,
      key: 'item.price',
      sorter: true,
      sortOrder: sorting.columnKey === 'item.price' ? sorting.order : undefined,
    }, {
      title: 'Доход',
      dataIndex: 'revenue',
      width: 85,
    },
      /*
      , {
        title: '',
        width: 140,
        render: (_: any, record) => (
          <RefundButtonContainer productItem={record} />
        ),
      }
      */];

    const columns: ColumnsType<ProductListItem> = [
      listType === ListType.ByBuyDate
        ? {
          title: 'Дата',
          dataIndex: 'created',
          width: 170,
          key: 'created',
          sorter: true,
          sortOrder: sorting.columnKey === 'created' ? sorting.order : undefined,
        }
        : {
          title: 'Дата',
          dataIndex: 'eventDate',
          width: 170,
          key: 'eventDate',
          sorter: true,
          sortOrder: sorting.columnKey === 'eventDate' ? sorting.order : undefined,
        },
      ...commonColumns,
    ];

    return (
      <>
        <ExtraTabSection>
          <Space className="clickpass-tabs-bar">
            <DatePicker.RangePicker
              value={listType === ListType.ByBuyDate ? filter.dates : filter.eventDates}
              onChange={(v) => {
                const newFilter = {
                  dates: listType === ListType.ByBuyDate ? v : null,
                  eventDates: listType === ListType.ByBuyDate ? null : v,
                };
                onSetFilter(newFilter);
              }}
            />
            <Button
              onClick={onExportCsv}
              icon={<DownloadOutlined />}
            >
              Скачать CSV
            </Button>
          </Space>
        </ExtraTabSection>
        <Table
          loading={loading}
          columns={columns}
          dataSource={productItems}
          rowKey="id"
          showSorterTooltip={false}
          // TODO: Косячит переключение в полноэкранный режим
          scroll={{ x: 2000, y: 'calc(100vh - 206px)' }}
          pagination={pagination}
          onChange={this.handleTableChange}
        />
      </>
    );
  }
}
