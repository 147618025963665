import React, { useState } from 'react';
import { useMount } from 'react-use';
import * as Yup from 'yup';
import { notification } from 'antd';

import { Balance } from './Balance';
import { submitValidate } from '../../../utils/form-helpers/antd-formik';
import { fetchOrganizerBalance, withdrawOrganizerAmount } from '../../../api/bookkeeping';

const validationSchema = (currentBalance: number) => Yup.object().shape({
  amount: Yup.number()
    .required()
    .min(100, 'Платеж должен быть больше 100 рублей')
    .max(currentBalance, 'Значение превышает баланс'),
});

export const BalanceContainer = () => {
  const [ loading, setLoading ] = useState(true);
  const [ balance, setBalance ] = useState<number>();
  useMount(async () => {
    try {
      const response = await fetchOrganizerBalance();
      setBalance(response.payload.organizerBalance);
    } finally {
      setLoading(false);
    }
  });
  return (
    <Balance
      loading={loading}
      balance={balance}
      onSubmit={async (values, formikBag) => {
        if (!submitValidate(values, validationSchema(balance ?? 0), formikBag)) {
          return;
        }
        try {
          await withdrawOrganizerAmount(values.amount);
          setTimeout(async () => {
            const balanceResponse = await fetchOrganizerBalance();
            setBalance(balanceResponse.payload.organizerBalance);
          }, 10000);
          notification.success({ message: 'Перевод поставлен в очередь' });
        } catch {
          notification.error({ message: 'Что-то пошло не так' });
        }
      }}
    />
  );
};
