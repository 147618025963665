export const PublicRoutes = {
  SignUp: '/signup',
  Restore: '/restore',
};

export const PrivateRoutes = {
  Products: '/products',
  Orders: '/orders',
  Purchases: '/purchases',
  PurchasesList: '/purchases/list',
  PurchasesByEventDate: '/purchases/by-event-date',
  Scanner: '/scanner',
  Partners: '/partners/:tab',
  LoginAs: '/login-as',
  ChildPartners: '/child-partners',
  Inspectors: '/inspectors',
  Account: '/account/:tab',
  Reports: '/reports',
  Balance: '/balance',
  BalanceInfo: '/balance/balance',
  BalanceWithdrawals: '/balance/withdrawals',
};
