import React from 'react';
import { Helmet } from 'react-helmet';
import { Tabs } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';

import './PurchasesPage.scss';

import { ApplicationLayout } from '../application/layouts/application-layout/ApplicationLayout';

import { PurchasesPageWrapper } from './products-summary/ProductsSummaryContainer';
import createPortal from '../../utils/createPortal';
import { PrivateRoutes } from '../../constants/routes';
import { ListType } from './products-summary/context/productSummaryListReducer';

const purchasesPageExtraTabId = 'purchases-page-extra-tab-id';
export const ExtraTabSection = createPortal(purchasesPageExtraTabId);

enum MenuKey {
  Items = 'items',
  ItemsByEventDate = 'itemsByEventDate',
}

const MenuRoutes: Record<MenuKey, string> = {
  [MenuKey.Items]: PrivateRoutes.PurchasesList,
  [MenuKey.ItemsByEventDate]: PrivateRoutes.PurchasesByEventDate,
};

export const PurchasesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const items = [
    {
      key: MenuKey.Items,
      label: 'По дате продажи',
      children: <PurchasesPageWrapper listType={ListType.ByBuyDate} />,
    },
    {
      key: MenuKey.ItemsByEventDate,
      label: 'По дате события',
      children: <PurchasesPageWrapper listType={ListType.ByEventDate} />,
    },
  ];

  let selectedKey: string = MenuKey.Items;
  if (location.pathname !== selectedKey) {
    const foundKey = Object.entries(MenuRoutes).find(([, value]) => {
      const regExp = new RegExp(`^${value}$`);
      return regExp.test(location.pathname);
    })?.[0];
    if (foundKey) {
      selectedKey = foundKey;
    }
  }

  return (
    <ApplicationLayout>
      <Helmet>
        <title>Организатор - Продажи</title>
      </Helmet>
      <div className="PurchasesPage">
        <Tabs
          destroyInactiveTabPane
          activeKey={selectedKey}
          onChange={(tab) => navigate(MenuRoutes[tab as MenuKey])}
          className="clickpass-tabs"
          tabBarExtraContent={{
            right: (
              <div id={purchasesPageExtraTabId} />
            ),
          }}
          items={items}
        />
      </div>
    </ApplicationLayout>
  );
};

export default PurchasesPage;
