import React from 'react';
import { Table } from 'antd';

import dayjs from 'dayjs';
import type { ColumnsType } from 'antd/lib/table';
import { Withdrawal } from '../../../api/bookkeeping';

interface Props {
  loading: boolean;
  withdrawals?: any[];
}

const columns: ColumnsType<Withdrawal> = [{
  title: 'ID',
  dataIndex: 'id',
  width: 350,
}, {
  title: 'Дата',
  dataIndex: 'createdAt',
  width: 200,
  render: (createdAt) => dayjs(createdAt).format('DD.MM.YYYY HH:mm'),
}, {
  title: 'Статус',
  dataIndex: 'status',
  width: 200,
  render: (status) => {
    switch (status) {
      case 'PENDING':
        return 'В очереди';
      case 'IN_PROGRESS':
        return 'Выполняется банком';
      case 'SUCCEEDED':
        return 'Завершено успешно';
      case 'FAILED':
        return 'Ошибка';
      default:
        return '';
    }
  },
}, {
  title: 'Сумма',
  width: 150,
  dataIndex: ['request', 'amount'],
}, {
  title: 'Описание',
  dataIndex: ['request', 'purpose'],
}];

export const Withdrawals = ({ loading, withdrawals }: Props) => {
  return (
    <Table<Withdrawal>
      loading={loading}
      columns={columns}
      dataSource={withdrawals}
      pagination={false}
      rowKey="id"
      style={{ width: '100%' }}
      // TODO: Косячит переключение в полноэкранный режим
      scroll={{ x: '100%', y: 'calc(100vh - 142px)' }}
    />
  );
};
