import { process } from '../env';
import { commonFetch } from './apiHelper';

export async function fetchOrganizerBalance() {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/balance`;
  const response = await commonFetch(requestURL, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

export enum WithdrawalStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export interface Withdrawal {
  id: string;
  createdAt: string;
  status: WithdrawalStatus;
  temporalWorkflowId: string | null;
  request: {
    amount: number;
    purpose: string;
  };
}

export async function fetchOrganizerWithdrawals(): Promise<{ withdrawals: Withdrawal[] }> {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/balance/withdrawal`;
  const response = await commonFetch(requestURL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return response.json();
}

export async function withdrawOrganizerAmount(amount: number) {
  const requestURL = `${process.env.BACKEND_URL}/api/v1/balance/withdrawal`;
  const response = await commonFetch(requestURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      amount,
    }),
  });
  return response.json();
}
