import { Dispatch } from 'react';
import dayjs from 'dayjs';

import {
  ListType,
  ProductSummaryListContextState,
  setProductSummaryListLoading,
  storeListItems,
  storePagination,
} from './productSummaryListReducer';
import {
  accountIdSelector,
  filterSelector,
  listTypeSelector,
  paginationSelector,
  sortingSelector,
} from './productSummaryListSelectors';
import { fetchPurchases, fetchPurchasesCsv, ProductSummaryFilter } from '../../../../api/orders';
import { saveFile } from '../../../../utils/fileDownload';

export const createListFilter = (filter: ProductSummaryFilter, listType: ListType): ProductSummaryFilter => {
  const apiFilter: ProductSummaryFilter = {
    dates: null,
    eventDates: null,
  };
  if (listType === ListType.ByBuyDate && filter.dates) {
    apiFilter.dates = [dayjs(filter.dates?.[0]).startOf('day'), dayjs(filter.dates?.[1]).endOf('day')];
  }
  if (listType === ListType.ByEventDate && filter.eventDates) {
    apiFilter.eventDates = [dayjs(filter.eventDates?.[0]).startOf('day'), dayjs(filter.eventDates?.[1]).endOf('day')];
  }
  return apiFilter;
};

export const getList = async (dispatch: Dispatch<any>, getState: () => ProductSummaryListContextState) => {
  const state = getState();
  const filter = filterSelector(state);
  const sorting = sortingSelector(state);
  const accountId = accountIdSelector(state);
  const pagination = paginationSelector(state);
  const listType = listTypeSelector(state);
  dispatch(setProductSummaryListLoading(true));
  const apiFilter = createListFilter(filter, listType);

  try {
    const result = await fetchPurchases(accountId, apiFilter, pagination, sorting);
    if (!result) {
      throw new Error('No data');
    }
    dispatch(storePagination({
      ...pagination,
      total: result.total,
    }));
    dispatch(storeListItems(result.multiTicketComponents, result.productItems));
  } finally {
    dispatch(setProductSummaryListLoading(false));
  }
};

export const getListCsv = async (dispatch: Dispatch<any>, getState: () => ProductSummaryListContextState) => {
  const state = getState();
  const filter = filterSelector(state);
  const sorting = sortingSelector(state);
  const accountId = accountIdSelector(state);
  const listType = listTypeSelector(state);
  const apiFilter = createListFilter(filter, listType);

  const result = await fetchPurchasesCsv(accountId, apiFilter, sorting);
  saveFile(result, result.type, 'Организатор-Продажи-Отдельные-товары.csv');
};
