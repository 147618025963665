import React from 'react';

import Menu from './Menu';
import { useAuthState } from '../../authentication/context/authContext';
import { accountSelector, createIsUserRoleSelector } from '../../authentication/context/authSelectors';
import { Role } from '../../../constants/rbac';

export const MenuContainer = () => {
  const state = useAuthState();
  return (
    <Menu
      account={accountSelector(state)}
      isAdministrator={createIsUserRoleSelector(Role.Administrator)(state)}
      isOrganizer={createIsUserRoleSelector(Role.Organizer)(state)}
      isDistributor={createIsUserRoleSelector(Role.Distributor)(state)}
      isInspector={createIsUserRoleSelector(Role.Inspector)(state)}
    />
  );
};

export default MenuContainer;
